@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Montserrat:wght@700&family=Poppins:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-heading {
    @apply font-construct-heading;
  }
}

body {
  @apply font-body;
  background: linear-gradient(to bottom right, #cce7ff, #e6ccff, #ffccff);
  background-size: cover;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-construct-heading dark:text-white;
}

.industrial-tech h1, .industrial-tech h2, .industrial-tech h3, .industrial-tech h4, .industrial-tech h5, .industrial-tech h6 {
  @apply font-industrial-heading;
}

/* Add this to your existing CSS */
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Add these classes to your existing CSS */
.perspective {
  perspective: 1000px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  backface-visibility: hidden;
}

.rotateY-180 {
  transform: rotateY(180deg);
}

/* New styles for card components */
.card {
  @apply bg-gradient-to-r from-blue-100 to-blue-200 dark:from-gray-700 dark:to-gray-800 p-8 rounded-lg shadow-lg hover:shadow-2xl transition duration-300;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  @apply text-2xl font-bold mb-4 font-heading dark:text-white;
}

.card-description {
  @apply dark:text-gray-300;
}

/* Update the hover-card styles */
.hover-card {
  @apply bg-gradient-to-r from-blue-100 to-blue-200 dark:from-gray-700 dark:to-gray-800 p-8 rounded-lg shadow-lg transition-all duration-300 ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateY(0) rotateX(0) rotateY(0);
  backface-visibility: hidden;
}

.hover-card:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(5deg);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Add this new style for the footer cards */
.footer-card {
  @apply bg-white dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-10 backdrop-blur-sm p-4 rounded-lg;
}

/* Add this to your existing CSS */

.ai-agents-section {
  overflow-x: hidden;
}

.agent-card {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.agent-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
  z-index: 1;
}

.agent-card > div {
  position: relative;
  z-index: 2;
}
