.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.particle {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.particle-background.dark .particle {
  background-color: rgba(255, 255, 255, 0.5);
}

.particle-background.light .particle {
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(10px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.particle-background .particle {
  animation: float 5s infinite ease-in-out;
}

.particle-background .particle:nth-child(2n) {
  animation-duration: 7s;
}

.particle-background .particle:nth-child(3n) {
  animation-duration: 9s;
}

.particle-background .particle:nth-child(4n) {
  animation-duration: 11s;
}

.particle-background .particle:nth-child(5n) {
  animation-duration: 13s;
}
